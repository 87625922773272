import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import InfiniteScroll from 'react-infinite-scroller';
import includes from 'array-includes';
import memoizeOne from 'memoize-one';
import media from '../utils/media';
import Page from '../components/page';
import Section from '../components/section';
import FlexRow from '../utils/flex-row';
import {
  LayoutRow,
  MainColumn,
  RightSideBackground,
} from '../utils/layout-row';
import BlogCard from '../components/blog-card';
import BlogTags from '../components/blog-tags';
import SubscribeFormIntro from '../components/subscribe-form-intro';
import SubscribeForm from '../components/subscribe-form';
import { BlogFilter, getFilterArgs } from '../components/blog-filter';

const objectPositionDefault = '25% 25%';
const StyledImg = styled(Img)`
    height: 100%;
    height: calc(100% - 90px);

    ${media.desk`
      height: calc(100% - 40px);
    `}

    img {
        object-fit: ${props => props.fit || 'cover'} !important;
        object-position: ${props => props.position || objectPositionDefault} !important;

        // for IE polyfill (object-fit-images)
        font-family: 'object-fit: cover !important; object-position: ${props =>
          props.position || objectPositionDefault} !important;'
    }
`;

const CardRow = styled(FlexRow)`
    align-items: flex-end;
`;

const CardColumn = styled.div`
  padding-bottom: 10px;

  ${media.desk`
    width: 75%;
    padding-bottom: 0;
  `}
`;

const TagColumn = styled.div`
  padding-bottom: 0;

  ${media.desk`
    width: 25%;
  `}
`;

const StyledBlogCard = styled(BlogCard)`
    margin-top: 40px;
`;

const BlogPostRow = ({ blogPostNode }) => {
  return (
    <Section as="article" gapMultiplier={0.5}>
      <LayoutRow>
        <RightSideBackground>
          <StyledImg
          fluid={blogPostNode.frontmatter.banner.image.childImageSharp.fluid}
          alt={blogPostNode.frontmatter.banner.alt}
          />
        </RightSideBackground>
        <MainColumn>
          <CardRow>
            <CardColumn>
              <StyledBlogCard
                slug={blogPostNode.fields.slug}
                title={blogPostNode.frontmatter.title}
                author={blogPostNode.frontmatter.author}
                date={blogPostNode.frontmatter.date}
                timeToRead={blogPostNode.timeToRead}
                summary={blogPostNode.frontmatter.summary} />
            </CardColumn>
            <TagColumn>
              <BlogTags tags={blogPostNode.frontmatter.tags}/>
            </TagColumn>
          </CardRow>
        </MainColumn>
      </LayoutRow>
    </Section>
  );
};

class BlogPage extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.shape({
      posts: PropTypes.object,
    }),
  };

  filterPosts = memoizeOne(
    (postEdges, search) => postEdges.filter(e => {
      const filterArgs = getFilterArgs(search);
      return (
        filterArgs.tags.length > 0
          ? e.node.frontmatter.tags.some(t => includes(filterArgs.tags, t))
          : true
        ) && (
        filterArgs.authors.length > 0
          ? includes(filterArgs.authors, e.node.frontmatter.author)
          : true
        )
    })
  );

  constructor(props) {
    super(props);

    this.showMorePosts = this.showMorePosts.bind(this);
    this.postsPerPage = 6;

    this.postEdges = this.props.data.posts.edges;

    const tagsAndAuthors = this.postEdges.reduce(
      (acc, e) => {
        e.node.frontmatter.tags.forEach(t => acc.tags.add(t));
        acc.authors.add(e.node.frontmatter.author);
        return acc;
      }
      , { tags: new Set(), authors: new Set() }
    );
    this.tags = [];
    this.authors = [];
    tagsAndAuthors.tags.forEach(t => this.tags.push(t));
    tagsAndAuthors.authors.forEach(a => this.authors.push(a));

    this.state = {
      postsToShow: this.postsPerPage,
    };
  }

  showMorePosts(pageNumber) {
    this.setState({
      postsToShow: pageNumber * this.postsPerPage,
    });
  }

  render () {
    const filteredPostEdges = this.filterPosts(this.postEdges, this.props.location.search);
    const hasMorePosts = filteredPostEdges.length > this.state.postsToShow;

    return (
      <Page location={this.props.location}>
        <main>
          <Helmet>
            <title>Blog</title>
            <meta
              name="description"
              content="Articles from Ari and Michael on branding, design, web and UX. Webology is design studio in Parramatta specialising in brand design, logos and websites."
            />
          </Helmet>
          <Section>
            <LayoutRow>
              <MainColumn>
                <BlogFilter
                  search={this.props.location.search}
                  navigate={this.props.navigate}
                  tags={this.tags}
                  authors={this.authors}
                />
              </MainColumn>
            </LayoutRow>
            <InfiniteScroll
              pageStart={1}
              loadMore={this.showMorePosts}
              hasMore={hasMorePosts}
              threshold={250}
              useWindow={true}
              >
              {filteredPostEdges.slice(0, this.state.postsToShow).map((e) => (
                <BlogPostRow
                  key={`${e.node.frontmatter.date}.${e.node.frontmatter.title}`}
                  blogPostNode={e.node} />
              ))}
            </InfiniteScroll>
          </Section>
          {
            hasMorePosts ? '' :
              <Section>
                <LayoutRow>
                  <MainColumn>
                    <SubscribeFormIntro />
                    <SubscribeForm />
                  </MainColumn>
                </LayoutRow>
              </Section>
          }
        </main>
      </Page>
    );
  }
}

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "blog" }, published: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            author
            summary
            banner {
              image {
                childImageSharp {
                  fluid(maxWidth: 2600, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              alt
            }
            tags
          }
        }
      }
    }
  }
`;

export default BlogPage;
